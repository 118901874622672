import * as s from './ArBlogHeader.module.scss'

import { Link, graphql } from 'gatsby'

import { ArArticleAuthors } from 'src/components/ArArticleAuthors'
import { ArContainer } from 'src/components/ArContainer'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import { TArBlogHeaderProps } from './ArBlogHeader.types'

export const ArBlogHeader: React.FC<TArBlogHeaderProps> = ({ article }) => {
  const categories = (
    <div className={s.categories}>
      <span>{article.isPressRelease ? 'Press' : 'Article'}</span>
      {' • '}
      {article.category?.map((cat, index) => (
        <React.Fragment key={index}>
          <span key={index}>{cat?.title}</span> {index !== article.category!.length - 1 && ' • '}
        </React.Fragment>
      ))}
    </div>
  )

  const imgUrl = article.featuredImage?.file?.url
  const imgTitle = article.featuredImage?.title || ''

  const authors = (article.author || []).map((item) => ({
    name: item?.name || '',
    image: item?.profilePhoto?.file?.url || '',
  }))

  return (
    <ArContainer className={s.container}>
      <h1>Blog</h1>
      <Link to={ROUTES.blogPost(article.slug!)}>
        <article>
          {imgUrl && <img src={imgUrl} alt={imgTitle} />}
          <div>
            <div>
              {categories}
              <h3>{article.title?.title}</h3>
              <p>{article.description?.description}</p>
            </div>
            {authors && <ArArticleAuthors className={s.authors} authors={authors} date={article.date} />}
          </div>
        </article>
      </Link>
    </ArContainer>
  )
}

export const query = graphql`
  fragment FeaturedPostData on ContentfulPost {
    id
    slug
    isPressRelease
    date
    title {
      title
    }
    description {
      description
    }
    featuredImage {
      file {
        url
      }
      title
    }
    author {
      name
      profilePhoto {
        file {
          url
        }
      }
    }
    category {
      title
    }
  }
`

import * as s from './ArBlogModule.module.scss'

import React, { useEffect, useState } from 'react'

import { ArArticleTile } from 'src/components/ArArticleTile'
import { ArContainer } from 'src/components/ArContainer'
import { ArSearchInput } from 'src/components/ArSearchInput'
import { ArSelectMenu } from 'src/components/ArSelectMenu'
import { ArTag } from 'src/components/ArTag'
import { TArBlogModuleProps } from './ArBlogModule.types'
import { graphql } from 'gatsby'
import useDeviceDetect from 'src/utils/useDeviceDetect'

const DEFAULT_POSTS_COUNT = 4

export const ArBlogModule: React.FC<TArBlogModuleProps> = ({ articles, categories }) => {
  const { isSmallDevice } = useDeviceDetect()
  const [postsCount, setPostsCount] = useState(DEFAULT_POSTS_COUNT)
  const [searchValue, setSearchValue] = useState('')
  const [activeCategoryId, setActiveCategoryId] = useState('')

  const allPosts = articles
    .filter((item) => (activeCategoryId ? item.node.category?.find((item) => item?.id === activeCategoryId) : true))
    .filter((item) => (searchValue ? item.node.title?.title?.toLowerCase().includes(searchValue.toLowerCase()) : true))
    .map((item) => item.node)

  const postsToShow = allPosts.slice(0, postsCount)
  const showLoadMoreButton = postsCount < allPosts.length && postsToShow.length > 0

  const onLoadMoreClick = () => {
    setPostsCount(postsCount + 4)
  }

  const onCategorySelect = (id: string) => {
    setActiveCategoryId(id)
    setPostsCount(DEFAULT_POSTS_COUNT)
  }

  const cats = [
    {
      title: 'All Articles',
      id: '',
    },
    ...categories.map((item) => ({
      title: item.node.title || 'UNDEFINED',
      id: item.node.id || '_',
    })),
  ]

  const activeCatItem = cats.find((item) => item.id === activeCategoryId)

  useEffect(() => {
    if (searchValue === '') {
      setPostsCount(DEFAULT_POSTS_COUNT)
    }
  }, [searchValue])

  return (
    <ArContainer full className={s.container} contentWrapperClassName={s.contentWrapper}>
      {!isSmallDevice && (
        <aside>
          <ArSearchInput
            className={s.search}
            value={searchValue}
            onChange={(val) => setSearchValue(val)}
            placeholder="Search articles"
          />
          <div className={s.categories}>
            {cats.map((item) => (
              <ArTag
                key={item.id}
                theme={activeCategoryId === item.id ? 'blue' : 'gray'}
                onClick={() => onCategorySelect(item.id)}>
                {item.title}
              </ArTag>
            ))}
          </div>
        </aside>
      )}
      <main>
        <h2>{searchValue ? 'Results for: ' + searchValue : 'Latest articles'}</h2>
        {isSmallDevice && (
          <div className={s.filtersMobile}>
            <ArSearchInput
              className={s.search}
              value={searchValue}
              onChange={(val) => setSearchValue(val)}
              placeholder="Search articles"
            />
            <ArSelectMenu
              className={s.filterDropdown}
              activeItemId={activeCategoryId}
              items={cats}
              onChange={(item) => onCategorySelect(item.id)}>
              <p>
                Filtered by <strong>{activeCatItem?.title}</strong>
              </p>
            </ArSelectMenu>
          </div>
        )}
        {postsToShow.length === 0 && searchValue && (
          <p>There are no results for ‘{searchValue}’. Please try another term.</p>
        )}
        <div className={s.articlesList}>
          {postsToShow.map((item) => (
            <ArArticleTile key={item.id} data={item} theme="white" style={{ maxWidth: 'none' }} />
          ))}
        </div>
        {showLoadMoreButton && (
          <button className={s.loadMore} onClick={onLoadMoreClick}>
            Load more
          </button>
        )}
      </main>
    </ArContainer>
  )
}

export const query = graphql`
  fragment PostData on ContentfulPost {
    id
    slug
    title {
      title
    }
    description {
      description
    }
    featuredImage {
      file {
        url
      }
      title
    }
    author {
      id
      name
      profilePhoto {
        file {
          url
        }
      }
    }
    category {
      title
      id
    }
    isPressRelease
    date
  }
`

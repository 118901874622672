import * as s from './ArSearchInput.module.scss'

import React from 'react'
import SvgSearch from 'src/assets/svg/search.svg'
import SvgXCross from 'src/assets/svg/xcross.svg'
import { TArSearchInputProps } from './ArSearchInput.types'
import classNames from 'classnames'

export const ArSearchInput: React.FC<TArSearchInputProps> = ({ className, style, value, placeholder, onChange }) => {
  return (
    <div className={classNames(s.container, className)} style={style}>
      <div>
        <SvgSearch className={s.searchIcon} />
      </div>
      <input value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
      {value.length > 0 && <SvgXCross className={s.crossIcon} onClick={() => onChange('')} />}
    </div>
  )
}

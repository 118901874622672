import * as s from './ArNewsletterForm.module.scss'

import { Controller, useForm } from 'react-hook-form'
import React, { useState } from 'react'

import { ArButton } from 'src/components/ArButton'
import { ArContainer } from 'src/components/ArContainer'
import { ArInput } from 'src/components/ArInput'
import { Link } from 'gatsby'
import { ROUTES } from 'src/config/routes'
import SvgOk from 'src/assets/svg/ok.svg'
import { TArNewsletterFormProps } from './ArNewsletterForm.types'
import classNames from 'classnames'

export const ArNewsletterForm: React.FC<TArNewsletterFormProps> = ({ className, style, grayBackground }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (formData: any) => {
    try {
      grecaptcha.ready(function () {
        grecaptcha.execute(process.env.GATSBY_RECAPTCHA_KEY, { action: 'submit' }).then(async (token: string) => {
          const result = await fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
              'form-name': 'contact',
              'data-netlify-recaptcha': true,
              'g-recaptcha-response': token,
              ...formData,
            }).toString(),
          })

          if (result.status === 200) {
            setIsSuccess(true)
          } else {
            throw new Error()
          }
        })
      })
    } catch (err) {
      alert('Something went wrong. Please try one more time.')
    }
  }

  const containerClass = classNames(s.container, className, {
    [s.grayBackground]: grayBackground,
  })

  return (
    <ArContainer className={containerClass} style={style} contentWrapperClassName={s.contentWrapper} full>
      <div>
        <h2>Never miss an update</h2>
        <p>
          Sign up for the latest insurtech insights with our mailing list and receive only the most relevant articles
          delivered straight to your inbox.
        </p>
      </div>
      <div>
        {isSuccess && (
          <div className={s.submitConfirmation}>
            <header>
              <SvgOk />
              <p>Success!</p>
            </header>
            <p>Success! Your email address has been saved. Next time we send out a newsletter, you’ll receive it.</p>
          </div>
        )}
        {!isSuccess && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={s.form}
            method="POST"
            name="newsletter"
            {...{ 'data-netlify': 'true' }}>
            <input type="hidden" name="form-name" value="newsletter" />
            <Controller
              name="fullname"
              control={control}
              defaultValue={''}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <ArInput label="Name" type="text" {...field} error={fieldState.error} />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue={''}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <ArInput label="Email address" type="email" {...field} error={fieldState.error} />
              )}
            />
            <ArButton type="submit" theme="primary">
              Sign me up
            </ArButton>
            {Object.keys(errors).length > 0 && (
              <p className={s.errorMessage}>
                One or more fields are missing, please fill out all of the required fields.
              </p>
            )}
            <p>
              By submitting your details, you agree to receive occasional marketing emails from us. We will never pass
              your details to any third parties. For further information on how we manage your personal data, visit our{' '}
              <Link to={ROUTES.privacyPolicy()}>Privacy Policy</Link>.
            </p>
          </form>
        )}
      </div>
    </ArContainer>
  )
}

import { ArBlogHeader } from 'src/components/ArBlogHeader'
import { ArBlogModule } from 'src/components/ArBlogModule'
import { ArNewsletterForm } from 'src/components/ArNewsletterForm'
import { ArSeo } from 'src/components/ArSeo'
import { BlogPageQuery } from 'gatsby-graphql'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import { graphql } from 'gatsby'

const BlogPage = ({ data }: { data: BlogPageQuery }) => {
  const featuredPost = data.featuredPost.edges[0].node
  const allPosts = data.allPosts.edges
  const allCategories = data.allCategories.edges

  return (
    <>
      <ArSeo route={ROUTES.blog()} />
      <ArBlogHeader article={featuredPost} />
      <ArBlogModule articles={allPosts} categories={allCategories} />
      <ArNewsletterForm />
    </>
  )
}

export const query = graphql`
  query BlogPage {
    featuredPost: allContentfulPost(
      limit: 1
      filter: { promoted: { eq: true } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          ...FeaturedPostData
        }
      }
    }
    allPosts: allContentfulPost(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          ...PostData
        }
      }
    }
    allCategories: allContentfulCategory {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`

export default BlogPage

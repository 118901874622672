import * as s from './ArSelectMenu.module.scss'

import React, { useState } from 'react'

import { ArTag } from 'src/components/ArTag'
import SvgArrow from 'src/assets/svg/arrow-right.svg'
import { TArSelectMenuProps } from './ArSelectMenu.types'
import classNames from 'classnames'

export const ArSelectMenu: React.FC<TArSelectMenuProps> = ({
  className,
  style,
  children,
  items,
  activeItemId,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={classNames(s.container, className)} style={style}>
      <ArTag className={s.button} onClick={() => setIsOpen(!isOpen)}>
        {children}
        <SvgArrow />
      </ArTag>
      <ul
        className={classNames(s.dropdown, {
          [s.isOpen]: isOpen,
        })}>
        {items.map((item, i) => (
          <li
            key={i}
            className={classNames({
              [s.active]: item.id === activeItemId,
            })}>
            <button
              onClick={() => {
                setIsOpen(false)
                onChange(item)
              }}>
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}
